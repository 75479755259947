@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$primary-color: #e5e5e5;
$secondary-color: #f2f2f2;
$secondary-light-color: #FAFAFA;
$body-bg: white;
$primary-text-black: #222222;
$placeholder-grey: #767676;
$primary-light-yellow: #F9DC4B;
$primary-darker-yellow: #F4C00F;
$primary-black: #181818;
$light-grey: #ECECEC;
$primary-gray: #444444;
$primary-red: #FF4000;
$primary-green: #05875B;
