@import "../../scss/variables.scss";
@import "scss/mixins";
$white: #ffffff;

@font-face {
  font-family: Gilroy;
  src: url('../../public/marketing-assets/font/Gilroy/GilroyExtraBold/font.woff'),
       url('../../public/marketing-assets/font/Gilroy/GilroyExtraBold/font.woff2');
  font-weight: 800;
}

@font-face {
  font-family: Gilroy;
  src: url('../../public/marketing-assets/font/Gilroy/GilroyBold/font.woff'),
  url('../../public/marketing-assets/font/Gilroy/GilroyBold/font.woff2');
  font-weight: 600;
}

@font-face {
  font-family: Gilroy;
  src: url('../../public/marketing-assets/font/Gilroy/GilroyMedium/font.woff'),
  url('../../public/marketing-assets/font/Gilroy/GilroyMedium/font.woff2');
  font-weight: 400;
}

@font-face {
  font-family: Gilroy;
  src: url('../../public/marketing-assets/font/Gilroy/GilroyMedium/font.woff'),
  url('../../public/marketing-assets/font/Gilroy/GilroyMedium/font.woff2');
  font-weight: normal;
}

@font-face {
  font-family: Grafik;
  src: url('../../public/marketing-assets/font/Graphik/Graphik-Bold-Web.woff'),
  url('../../public/marketing-assets/font/Graphik/Graphik-Bold-Web.woff2');
  font-weight: 800;
}

@font-face {
  font-family: Grafik;
  src: url('../../public/marketing-assets/font/Graphik/Graphik-Regular-Web.woff'),
  url('../../public/marketing-assets/font/Graphik/Graphik-Regular-Web.woff2');
  font-weight: 400;
}

@font-face {
  font-family: Grafik;
  src: url('../../public/marketing-assets/font/Graphik/Graphik-Regular-Web.woff'),
  url('../../public/marketing-assets/font/Graphik/Graphik-Regular-Web.woff2');
  font-weight: normal;
}

.marketingSite :global {
  font-weight: 400;
  font-size: 17px;
  color: $primary-black;
  font-family: Gilroy, sans-serif;

  .font-graphik {
    font-family: Graphik, sans-serif;
  }

  label {
    font-family: Graphik, sans-serif;
  }

  .text-right {
    text-align: right !important;
  }

  .w-50 {
    @media (max-width: 992px) {
      width: 100% !important;
    }
  }

  #success-submit {
    display: none;

    p {
      font-size: 24px;
    }

    &.show {
      display: block;
    }
  }

  .input-group-text {
    border: 1px solid #B8B6B6;
  }

  .form-control, .form-select {
    height: 48px;
    padding: calc(0.375rem + 1px) calc(0.75rem + 1px);
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    color: #222222;
    border: 1px solid #B8B6B6;

    &::placeholder {
      color: #767676;
    }

    &:disabled {
      border: 1px solid #E4E4E7;
      background-color: #F4F4F5;
      opacity: 1;
    }

    &:focus {
      padding: 0.375rem calc(0.75rem + 1px) 0.375rem 0.75rem;
      border: 2px solid #222222;
      box-shadow: 0px 0px 0px 3px $primary-light-yellow;
    }
  }

  a {
    color: #181818;
  }

  #contactSuccess {
    display: block;

    h2 {
      font-family: Gilroy, sans-serif;
    }

    &.hide {
      display: none;
    }

    video {
      background-color: black;
      display: flex;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      height: 320px;
    }
  }

  #error-submit {
    display: none;

    &.show {
      display: flex;
    }
  }

  small {
    font-size: 12px;
  }

  h2.alt-header {
    font-size: 56px;

    @media (max-width: 992px) {
      font-size: 36px;
    }
  }

  .navbar {
    background-color: $primary-black;
    height: 72px;

    .navbar-collapse {
      &.collapsing {
        height: auto;
        overflow: visible;
        transition: none;
      }
    }

    .navbar-nav {
      padding-left: 30px;
      margin-right: auto;
      .nav-link {
        color: #ffffff;
        padding: 8px 30px;
        font-weight: 800;

        &.active {
          color: $primary-light-yellow;
        }

        &:hover, &:focus {
          color: $primary-light-yellow;
        }
      }

      @media (max-width: 992px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #181818;
        z-index: 9999;
        padding: 60px 40px;

        .navbar-toggler {
          padding: 0;
        }

        .logo-container {
          align-items: flex-start;
          margin-bottom: 30px;
        }

        .nav-link {
          font-weight: 800;
          font-size: 36px;
          padding: 5px 0;

          &.small {
            font-weight: 400;
            font-size: 14px;
          }

          &.signin-consumer {
            font-size: 18px;
          }
        }

        p {
          color: #ffffff;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .alert.alert-success {
    background: #F5FFF2;
    border: 2px solid #00DD42;
    border-radius: 8px;

    svg {
      margin-right: 12px;
    }
  }

  .alert.alert-danger {
    display: flex;
    background: #FFF5E6;
    border: 2px solid #FF4000;
    border-radius: 8px;

    svg {
      margin-right: 12px;
    }
  }

  .numNoArrows {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  footer {
    background-color: $primary-black;
    padding: 64px 0;
    color: $white;
    @media (max-width: 767px) {
      padding: 50px 0 39px 0;
    }
    @media (max-width: 576px) {
      button {
        width: 100%;
      }
    }

    .footer-logo {
      margin-bottom: 32px;
      img {
        @media (min-width: 768px) and (max-width: 1200px) {
          width: 100px;
        }
        @media (max-width: 767px) {
          width: 80px;
        }
      }
      @media (max-width: 992px) {
        margin-bottom: 64px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .footer-header {
          margin-bottom: 24px;
          line-height: 1;
        }

        .btn {
          width: 200px;
        }
      }

    }

    .footer-header {
      line-height: 125%;
      margin-bottom: 18px;
      font-size: 36px;
      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 34px !important;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 30px;
      }
      @media (max-width: 575px) {
        font-size: 16px;
      }
    }

    .footer-links {
      flex: 1;

      p {
        font-size: 21px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          display: block;

          a {
            font-size: 14px;
          }
        }
      }
      .proudly-serves-bp {
        @media (min-width: 993px) {
          flex: 0 0 auto;
          width: 100%;
        }
      }
    }

    a {
      font-family: 'Graphik', sans-serif;
      color: #FFFFFF;
      text-decoration: none;
      &:hover {
        color: $primary-light-yellow;
      }
    }

    .footer-menu-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $white;

      .footer-menu {
        a {
          display: inline-block;
          font-size: 21px;
          font-weight: 800;
          padding-right: 50px;
        }
      }

      .social-links-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        > a:first-child {
          font-size: 16px;
          font-weight: 800;
        }
        @media (min-width: 993px) {
          width: unset;
          > a:first-child {
            display: none;
          }
        }
      }

      .footer-social-links {
        a {
          margin-left: 16px;
        }

        @media (max-width: 992px) {
          display: flex;
          justify-content: space-around;
          gap: 4px;
          a {
            margin-left: 0;
          }
        }
      }

      @media (max-width: 992px) {
        flex-direction: column;

        .footer-menu {
          gap: 8px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid $white;
          padding-bottom: 20px;
          margin-bottom: 20px;
          a {
            padding: 0;
            font-size: 16px;
          }
        }
      }
    }

    .footer-copyright {
      padding-top: 20px;
      font-family: 'Graphik', sans-serif;
      font-size: 14px;
      line-height: 15px;
      color: #FFFFFF;
      > a {
        font-size: 21px;
        font-weight: 800;
      }
      div {
        flex: 1;

        &:nth-child(2) {
          flex: 2;
          text-align: center;
          a {
            padding: 0 25px;
          }
          @media (max-width: 400px) {
            display: flex;
            width: 100%;
            text-align: initial;
            justify-content: space-between;
            a {
              padding: 0 15px;
            }
          }
        }
      }

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: center !important;
        gap: 20px;

        > a {display: none;}

        div:first-child {
          order: 1;
          text-align: center;
        }
      }
    }


  }

  .custom-container {
    @media (max-width: 1150px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .badge {
    &.badge-purr {
      background-color: $primary-light-yellow !important;
      color: $primary-black;
      border-radius: 20px;
      font-weight: 400;
    }
  }

  section {
    padding: 64px 0;
    @media (max-width: 767px) {
      &:first-of-type {
        padding: 20px 0 48px 0;
      }

      &:not(:first-of-type) {
        padding: 48px 0;
      }
    }
    &.bg-gray {
      background-color: $light-grey;
    }

    &.privacy {
      padding-bottom: 250px;
      @media (max-width: 992px) {
        padding-bottom: 100px;
      }
      h1 {
        font-weight: 800;
        font-size: 76px;
        padding: 184px 0;

        @media (max-width: 992px) {
          font-size: 36px;
          padding: 50px 0;
        }
      }
    }

    &.car-background {
      background: url('../../public/marketing-assets/images/output-onlinepngtools.png') center right no-repeat;
      background-size: 50%;

      @media (max-width: 767px) {
        background-size: 370px;
        background-position-x: calc(100% + 190px);
      }

      @media (min-width: 1440px) {
        background-size: 700px;
        background-position: calc(50% + 370px);
      }

      .btn-purr {
        margin-bottom: 138px;
      }
    }

    &.bg-white {
      background-color: $white;
    }

    &.bg-dark {
      background-color: #181818 !important;
    }

    &.masthead {
      position: relative;
      padding-top: 95px;
      padding-bottom: 95px;
      overflow: hidden;
      background-color: #ffffff;

      .masthead-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content {
          width: 45%;

          .nav-tabs {
            border-bottom: 2px solid #B8B6B6;

            .nav-item {
              width: 50%;
              margin-bottom: -3px;
              .nav-link {
                width: 100%;
                font-weight: 800;
                font-size: 18px;
                text-align: left;
                color: #000000;
                border: 0 !important;

                &.active {
                  border-bottom: 7px solid $primary-light-yellow !important;
                }
              }
            }
          }
        }
        .hero-container {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 55%;
          margin-right: -5%;
          margin-top: 6%;

          .circle {
            width: 160%;
            padding-bottom: 159%;
            background-color: $primary-light-yellow ;
            border-radius: 9999px;
            position: absolute;
            margin-top: -27%;
          }
          .hero {
            width:100%;
            height:100%;
            display:block;
            overflow: hidden;
            transform: translate3d(0,0,0);
            text-align: center;
            opacity: 1;
          }
        }
        @media (max-width: 992px) {
          flex-direction: column;
          .content {
            width: 100%;
          }
          .hero-container {
            width: 100%;
            justify-content: center;
            align-items: flex-start;
          }
        }
      }

      &.buyer {
        @media (max-width: 992px) {
          .hero-container {
            .circle {
              margin-top: 13%;
            }
          }
        }
      }

      &.seller {
        @media (max-width: 992px) {
          .hero-container {
            .circle {
              margin-top: 16%;
            }
          }
        }
      }

      &.dealer {
        background-color: #444444;

        .hero-container {
          .circle {
            background-color: #767676;
          }
        }

        h1 {
          color: #ffffff;
          font-size: 56px;
        }

        h2 {
          color: #ffffff;
          font-size: 28px;
          margin-top: 30px;
        }

        .btn-purr {
           padding-right: 50px;
           padding-left: 50px;
        }
        .content {
          width: 50%;
        }

        @media (max-width: 992px) {
          .content {
            width: 100%;
          }
          .hero-container {
            .circle {
              margin-top: 16%;
            }
          }
          h1 {
            font-size: 36px;
          }

          h2 {
            font-size: 20px;
          }
        }
      }

      &.about {
        background-color: $light-grey;

        @media (max-width: 992px) {
          height: auto;
        }
        h1 {
          font-size: 56px;

          @media (max-width: 992px) {
            font-size: 36px;
          }
        }

        .content {
          width: 100%;
        }

        .banner-image {
          margin-top: -50px;
          @media (max-width: 992px) {
            margin-top: 20px;
          }
          .hero {
            width: 100%;
            max-width: 700px;
            margin-top: -75px;
            @media (max-width: 992px) {
              margin-top: 0px;
            }
          }
        }
      }

      &.contact-success {
        height: 627px;
        background: #ECECEC url('../../public/marketing-assets/images/contact-confirmation.png') no-repeat bottom center;
        h1 {
          font-size: 76px;
        }

        h3 {
          font-weight: 800;
          font-size: 36px;
        }
      }

      &.contact {
        padding-top: 90px;
        padding-bottom: 90px;
        background-color: $light-grey;

        h1 {
          font-size: 76px;
        }

        h3 {
          font-weight: 800;
          font-size: 36px;
        }
        @media (max-width: 767px) {
          padding-top: 40px;
          padding-bottom: 20px;

          h1 {
            font-size: 36px;
          }
          h3 {
            font-size: 21px;
          }
          img {
            max-width: unset;
          }
        }
      }


      //&::before {
      //  content: '';
      //  position: absolute;
      //  height: 637px;
      //  width: 50%;
      //  background: url('images/yellow-circle.svg') no-repeat bottom right;
      //  background-position: center 70%;
      //  top: 0;
      //  right: 0;
      //}

      @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 0;
      }

      h5 {
        font-size: 21px;
        font-weight: 800;
      }
      h1 {
        font-size: 36px;
        font-weight: 800;

        @media (max-width: 992px) {
          font-size: 29px;
        }
      }
      p {
        max-width: 530px;
        margin: 32px 0;
      }

      img {
        max-width: 487px;
        width: 100%;
      }
    }
  }

  h1, .h1 {
    font-size: 56px;
    font-weight: 800;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  h2, .h2{
    font-size: 40px;
    font-weight: 800;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 800;
  }

  .challenges {
    @media (max-width: 992px) {
      &.above-cat {
        padding-bottom: 175px !important;
      }
    }
    p.alt-header {
      text-align: center;
      font-size: 20px;
      @media (max-width: 767px) {
        font-weight: 800;
        font-size: 18px;
      }

    }
    h2.alt-header {
      text-align: center;
      margin-bottom: 50px;
    }
  }

  strong {
    font-weight: 800;
  }

  .calculator {
    color: #ffffff;
    padding-bottom: 0;

    label {
      font-size: 14px;
    }

    h2 {
      font-size: 32px;
      @media (max-width: 992px) {
        font-size: 22px;
      }
    }

    .profit-table {
      margin-bottom: 60px;
      p {
        font-size: 16px;
      }
    }

    .badge.badge-warning {
      background: #FDE047;
      border-radius: 8px;
      font-weight: 800;
      font-size: 29px;
      color: #181818;
      @media (max-width: 992px) {
        font-size: 20px;
      }
    }

    form {
      padding-bottom: 64px;
    }

    .result-table {
      color: #181818;
      background: #FFFFFF;
      text-align: center;
      border: 1px solid #D4D4D8;
      border-radius: 8px;

      a {
        font-weight: 400;
        font-size: 14px;
        color: #181818;
      }

      .table {
        color: #181818;
        background: #FFFFFF;
        border-radius: 8px;
        overflow: hidden;
        td {
          text-align: left;
          font-weight: 400;
          font-size: 16px;

          &:first-child {
            padding-left: 1.5rem;
          }

          &:last-child {
            padding-right: 1.5rem;
          }

          @media (max-width: 767px) {
            padding: 0.5rem 0.4rem;
            font-size: 14px;

            &:first-child {
              padding-left: 0.5rem;
            }

            &:last-child {
              padding-right: 0.5rem;
            }
          }
        }

      }

      th {
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        padding: 0.5rem 1.5rem;
        @media (max-width: 767px) {
          padding: 0.5rem
        }
      }

      tbody {
        tr:first-child {
          td {
            font-weight: 700;
            font-size: 18px;
          }
          .badge {
            background: #18181B;
            border-radius: 8px;
            padding: 8px;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }

      .table-bordered>:not(caption)>*>* {
        border-width: 0;
      }

      .table-bordered>:not(caption)>* {
        border-width: 0;
      }

      .table>:not(:first-child) {
        border-top: 1px solid #E4E4E7;
      }
    }

    @media (max-width: 992px) {
      padding-bottom: 0 !important;
      .custom-container {
        flex-direction: column;
        padding: 0 20px;
      }
    }

    .slider-wrapper {
      color: #181818;
      background: #FFFFFF;
      text-align: center;
      padding: 24px;
      border: 1px solid #D4D4D8;
      border-radius: 8px;

      h5 {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px
      }

      p {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 16px;

        span {
          text-decoration: underline;
        }
      }
    }
  }

  .promotion {
    background: url('../../public/marketing-assets/images/car-lines.png') no-repeat bottom right;
    background-size: 100%;
    min-height: 600px;
    @media (min-width: 200px) {
      background-position: 0 calc(50% + 100px);
    }
    @media (max-width: 767px) {
      min-height: 400px;
      background-size: 140%;
      background-position: 45% calc(50% + 60px);
      h2 {
        font-size: 18px;
      }
    }
    @media (max-width: 576px) {
      min-height: 300px;
      background-size: 700px;
      background-position: calc(100% + 110px) calc(50% + 60px);
      button {
        width: 100%;
      }

    }
  }

  .brands {
    color: #ffffff;
    .swiper-slide {
      text-align: center;
      img {
        max-width: 120px;
      }
    }

    .slider-wrapper .swiper-button-next, .slider-wrapper .swiper-button-prev {
      color: #ffffff;
    }
  }

  .how-it-works-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > div {
      width: calc(50% - 25px);
    }
    video {
      width: 100%;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  }

  .buyer-how-it-works {
    &.dark {
      background-color: #444444;
      color: #ffffff;

      .how-it-works-carousel-wrapper {
        .slider-wrapper {
          .swiper-button-next, .swiper-button-prev {
            color: #ffffff;
          }
        }
      }
    }
    .custom-container {
      position: relative;

      .cat {
        position: absolute;
        width: 267px;
        left: 0;
        top: -145px;
        @media (max-width: 992px) {
          width: 180px;
          top: -100px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
    .how-it-works-carousel-wrapper {
      max-width: 100%;

      .slider-wrapper {
        position: relative;
        .buttons-container {
          position: absolute;
          width: 44%;
          left: 0;
          bottom: 0%;
          z-index: 10;
        }
        .swiper-button-next:after, .swiper-button-prev:after {
          font-size: 17px;
          font-weight: 800;
        }

        .swiper-button-next, .swiper-button-prev {
          color: #000000;
          top: auto;
          bottom: 70px;
          @media (max-width: 992px) {
            bottom: 0;
          }
        }

        .swiper-button-next {
          left: 40px;
          @media (max-width: 992px) {
            left: auto;
            right: 0px;
            z-index: 200;
          }
        }

        .swiper-button-prev {
          left: 0px;
          @media (max-width: 992px) {
            left: 0px;
            z-index: 200;
          }
        }
      }

      .swiper-pagination {
        position: absolute !important;
        width: auto;
        bottom: 40px;

        @media (max-width: 992px) {
          width: 100%;
          bottom: 10px;
        }
      }

      .swiper-slide {
        padding-top: 35px;
        padding-bottom: 0;
        position: relative;
        .slide-container {
          display: flex;
          justify-content: space-between;
          height: 100%;

          .illustration-container {
            text-align: right;
            .illustration {
              width: 50%;
              @media (max-width: 992px) {
                width: 75%;
              }
            }
          }
          .texts-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
              font-weight: 800;
              font-size: 36px;
              margin-bottom: 45px;
              padding-right: 80px;
            }
            .text-right {
              text-align: right;
            }
            .number {
              top: 15px;
              right: 0;
              position: absolute;
            }
          }

        }
      }

      @media (max-width: 992px) {
        max-width: 100%;

        .swiper-slide {
          display: flex;
          flex-direction: column;
          .slide-container {
            flex-direction: column;
            .texts-container h3 {
              padding-right: 0;
            }

          }
          h3 {
            order: 1;
          }

          p {
            order: 2;
          }

          img {
            order: 0;
          }

          .number {
            top: 15px;
            right: auto;
            left: 0;
            width: 50px;
            position: absolute;
          }
        }
        .slider-wrapper {
          .buttons-container {
            position: relative;
            height: 44px;
            width: 100%;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .yellow-bottom-circle {
    min-height: 700px;
    background-image: url("../../public/marketing-assets/images/yellow-background-about.png");
    background-position: center top;
    background-size: cover;

    @media (max-width: 992px) {
      min-height: 400px;

      h2.alt-header {
        font-size: 36px;
      }
    }
  }

  .how-it-works {
    padding-bottom: 0 !important;
    @media (max-width: 767px) {
      .container > h2 .underline {
        background-image: none;
      }
      p {
        font-size: 16px;
      }
    }
    .how-it-works-items {
      background: url('../../public/marketing-assets/images/bigline.svg') repeat-y top center;
      padding-top: 120px;
      padding-bottom: 200px;
      margin-top: 120px;
      @media (max-width: 992px) {
        background: url('../../public/marketing-assets/images/bigline-mobile.png') repeat-y top center;
        background-size: 225px;
        background-position-x: center;
        padding-top: 190px;
        padding-bottom: 90px;
        margin-top: 60px;
      }
      @media (max-width: 370px) {
        background-size: 210px;
      }

      .step {
        width: 80px;
        height: 80px;
        left: 440px;
        top: 0;
        background: $primary-light-yellow;
        border-radius: 160px;
        display: block;
        font-size: 32px;
        text-align: center;
        line-height: 80px;
        font-weight: 800;
        margin-bottom: 24px;
        @media (max-width: 767px) {
          width: 40px;
          height: 40px;
          font-size: 20px;
          line-height: 40px;
          left: 10px;
        }
      }

      .how-it-works-item {
        flex-direction: row;
        align-items: center;
        margin-bottom: 50px;
        @media (max-width: 992px) {
         flex-direction: column-reverse !important;
         align-items: center;
          margin-bottom: 145px;
        }
        > div {
          flex: 1 0 50%;
          h4 {
            font-weight: 800;
            font-size: 21px;
            max-width: 330px;
            @media (max-width: 992px) {
              font-size: 18px;
              text-align: center;
            }
          }
        }

        img {
          max-width: 416px;
          width: 100%;

          @media (max-width: 992px) {
            max-height: 300px;
          }
        }
      }

      > div {
        &:last-child {
          .how-it-works-item {
            margin-bottom: 0;
          }
        }
      }
    }

    h4 {
      font-size: 36px;
    }

    h2.alt-header {
      font-size: 76px;
    }
    @media (max-width: 767px) {
      h4{
        font-size: 21px;
      }
      h2.alt-header {
        font-size: 36px;
      }
    }
  }

  .form {

    .custom-container {
      background: $white;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 48px;
    }
    @media (max-width: 767px) {
      padding-bottom: 0 !important;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: block;

        a {
          font-size: 14px;
          font-family: 'Graphik', sans-serif;
          color: #181818;
          text-decoration: none;
        }
      }
    }

    form {
      width: 100%;
      position: relative;

      #submit {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .spinner-border {
          display: none;
        }

        &.loading {
          .spinner-border {
            display: inline-block;
          }
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        height: auto;
        padding: 48px 0px;
        border-radius: 0;
        margin-bottom: 0;
      }

      .cat-image {
        position: absolute;
        width: 73px;
        height: 42px;
        right: 71px;
        top: -25px;
      }

      .btn-purr {
        width: 100%;
      }
    }
  }

  .winners {
    h2 {
      span {
        display: inline-block;
        max-width: 760px;
      }
    }

    h4 {
      font-weight: 800;
      font-size: 21px;
      @media (max-width: 767px) {
        font-size: 18px;
      }
      span {
        display: inline-block;
        max-width: 640px;
      }
    }

    .image-container {
      margin-bottom: 40px;
      margin-top: 100px;
      justify-content: center;
      @media (max-width: 767px) {
        margin-bottom: 60px;
        margin-top: 46px;
        width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
      }

      img {
        max-width: 845px;
        //content: url(./images/winners_mobile.png);
      }
    }

    @media (max-width: 576px) {
      button {
        width: 100%;
      }
    }
  }

  .features {
    .container {
      padding-bottom: 70px;
      @media (max-width: 767px) {
        padding-bottom: 0px;
        > p {
          width: 70%;
        }
        > h2 .underline {
          background-image: none;
        }
      }
      @media (max-width: 576px) {
        background-size: 100px;
      }
    }

    h4 {
      font-size: 24px;
      font-weight: 800;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
      }

      li {
        position: relative;
        display: inline-block;
        width: 49%;
        padding: 0 20px 32px 28px;
        vertical-align: top;
        @media (max-width: 767px) {
          width: 100%;
          h4 {
            padding: 0 0 0 24px;
          }
          padding: 0px;
          &:last-child {
            padding-bottom: 0px;
            p {
              margin-bottom: 0px;
            }
          }
        }

        &:before {
          content: '';
          height: 16px;
          width: 16px;
          border-radius: 10px;
          position: absolute;
          background-color: $primary-light-yellow;
          top: 5px;
          left: 0px;
          @media (max-width: 767px) {
            height: 12px;
            width: 12px;
          }
        }

        &:last-child {
          &:before {
            content: none;
          }
        }
      }
    }
  }

  .stats {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 50px;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .stats-item {
      flex: 1 0 30%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      @media (max-width: 992px) {
        flex: 1 0 100%;
      }
      h4 {
        font-size: 21px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 18px;
      }

      > div {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 57px 25px 19px;
        position: relative;
        height: 100%;

        p {
          text-align: center;
          font-weight: 400;
          font-size: 18px;
          margin-bottom: 0;
        }

        .testimonial {
          img {
            width: 70px;
          }

          p {
            text-align: left;
            margin-bottom: 0;
            line-height: 12px;

            &.small-text {
              font-size: 14px;
            }
          }
        }

        .stat-image {
          position: absolute;
          height: 85px;
          width: 85px;
          left: calc(50% - 43px);
          top: -43px;
        }

        .stat-number {
          position: absolute;
          width: 100px;
          height: 100px;
          left: calc(50% - 50px);
          top: calc(50% - 150px);
          background: $primary-light-yellow;
          border-radius: 70px;
          font-weight: 800;
          font-size: 32px;
          text-align: center;
          line-height: 100px;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 64px;
            width: 64px;
            height: 64px;
            left: 0;
            top: -12px;
          }
        }
      }
    }
  }

  .our-values {
    .stats {
      gap: 20px;
      .stats-item {
        flex: 1 0 23%;

        > div {
          padding-top: 45px;
          border: 1px solid #ececec
        }
      }
    }
  }
  .history {
    overflow: hidden;
    .cat-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;

      .cat-container {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: $primary-light-yellow;
        border-radius: 9999px;
        align-items: center;
        justify-content: center;

        .cat {
          position: absolute;
          width: 150%;
          margin-left: -10%;
          margin-top: 10%;
        }

      }
    }

  }

  .our-team {
    .swiper-slide {
      text-align: center;
      .team-member {
        max-width: 143px;
        margin: 0 auto;
      }

      h5 {
        font-size: 21px;
        font-weight: 800;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .slider-wrapper {
    position: relative;
    .swiper-button-next:after, .swiper-button-prev:after {
      font-size: 17px;
      font-weight: 800;
    }

    .swiper-button-next, .swiper-button-prev {
      color: #000000;
      top: calc(50% - 30px);

      @media (max-width: 991px) {
        bottom: -10px;
        top: auto;
      }
    }

    .swiper-button-next {
      right: -50px;
      @media (max-width: 991px) {
        right: 55px;
      }
    }

    .swiper-button-prev {
      left: -50px;
      @media (max-width: 991px) {
        left: 55px;
      }
    }
  }

  .swiper-slide {
    height: auto !important;
    padding-bottom: 40px;

    img {
      max-width: 100%;
    }
    .stats-item {
      margin-bottom: 0;
      height: 100%;

      >div {
        padding-top: 27px;

        p {
          text-align: left;
        }
      }
    }
  }

  .swiper-pagination {
    position: static !important;
    .swiper-pagination-bullet {
      display: inline-block;
      border-radius: 50%;
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
      opacity: 1;
      background: #C4C4C4;
      height: 9px;
      width: 9px;
      cursor: pointer;
    }
    .swiper-pagination-bullet-active {
      background: $primary-light-yellow;
    }
  }

  .btn-purr {
    border-radius: 120px;
    background-color: $primary-light-yellow;
    color: $primary-black;
    font-weight: 800;
    font-size: 18px;
    padding: 10px 16px;
    font-family: Gilroy, sans-serif;

    &:hover {
      background-color: $primary-light-yellow;
      color: $primary-black;
    }
    &:focus {
      box-shadow: none;
      background-color: $primary-light-yellow;
      color: $primary-black;
    }

    &.dark {
      background-color: $primary-black;
      color: $white;

      &:hover {
        background-color: $white;
        color: $primary-black;
      }
    }

    &.signin-consumer {
      padding: 6px 16px;
      width: 130px;
    }
  }

  .underline {
    display:inline;
    background-image:url('../../public/marketing-assets/images/underline-segment.png');
    background-size:2em 0.5em;
    background-repeat: repeat-x;
    background-position:0 85%;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;

    &.white {
      background-image:url('../../public/marketing-assets/images/underline-segment-white.png');
    }
  }

  .hide-in-mobile {
    @media (max-width: 992px) {
      display: none !important;
    }
  }

  .hide-in-large-screens {
    @media (min-width: 993px) {
      display: none !important;
    }
  }

  .ui-slider-range {
    background-color: #E4E4E7;
  }

  .ui-widget.ui-widget-content {
    border-radius: 15px;
    background-color: #181818;
  }

  .ui-slider-handle {
    width: 1.2em;
    height: 1.2em;
    border-radius: 50px !important;
    border: 4px solid #ffffff !important;
    background-color: #18181B !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    div {
      position: relative;
    }
    span {
      position: absolute;
      width: 50px;
      height: 30px;
      background: #18181B;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      border-radius: 6px;
      top: -42px;
      left: -18px;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: #181818 transparent transparent transparent;
        position: absolute;
        bottom: -5px;
        left: 20px;
      }
    }
  }

  .homepage {
    h1 {
      @media (max-width: 767px) {
        font-size: 36px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 40px;
      }
      &.alt-header {
        text-align: center;
      }
    }
    section {
      @media (min-width: 768px) and (max-width: 991px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .intro-section {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      @media (min-width: 768px) {
        padding-top: 30px;
      }
      @media (max-width: 610px) {
        margin-bottom: 60px;
      }
      .container {position: relative;}
      .main-content {
        display: flex;
        align-items: center;
        > * {
          flex: 1;
        }
        @media (max-width: 610px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .illustration-container {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          @media (max-width: 610px) {
            justify-content: center;
          }
          .illustration {
            max-width: 100%;
            max-height: 390px;
            @media (max-width: 767px) {
              max-height: 220px;
            }
          }
        }
      }
    }
    .social-section {
      padding: 0;
      display: flex;
      width: 100%;
      .review {
        gap: 4px;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 20px;
        padding-left: calc(1.5rem * 0.5);
        border-top-right-radius: 20px;
        margin-right: auto;
        .rating-text {
          font-weight: 800;
          font-size: 14px;
          line-height: 125%;
          color: #000000;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          padding-left: calc(20px + calc(1.5rem * 0.5));
        }
        @media (min-width: 992px) {
          padding-left: calc((((100% - 960px) / 2) + calc(1.5rem * 0.5) ));
        }
        @media (min-width: 1200px) {
          padding-left: calc((((100% - 1140px) / 2) + calc(1.5rem * 0.5) ));
        }
        @media (min-width: 1400px) {
          padding-left: calc((((100% - 1320px) / 2) + calc(1.5rem * 0.5) ));
        }
      }
      .social-network {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        @extend .review;
        padding-left: 20px;
        padding-right: calc(1.5rem * 0.5);
        @media (max-width: 400px) {
          padding-right: 8px;
          padding-left: 8px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          padding-right: calc(20px + calc(1.5rem * 0.5));
        }
        @media (min-width: 992px) {
          padding-right: calc((((100% - 960px) / 2) + calc(1.5rem * 0.5) ));
        }
        @media (min-width: 1200px) {
          padding-right: calc((((100% - 1140px) / 2) + calc(1.5rem * 0.5) ));
        }
        @media (min-width: 1400px) {
          padding-right: calc((((100% - 1320px) / 2) + calc(1.5rem * 0.5) ));
        }
        .footer-social-links {
          position: relative;
          display: flex;
          align-items: center;
          gap: 11px;
        }
        .watch-how-it-works {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          gap: 8px;
          position: absolute;
          top: 5;
          right: 160px;
          white-space: nowrap;
          background: #F9DC4B;
          border-radius: 50px;
          height: 47px;
          width: fit-content;
          font-weight: 800;
          font-size: 18px;
          color: #000000;
          cursor: pointer;
          @media (max-width: 610px) {
            top: -100px;
            right: calc((100vw - 20px - 0.75rem - 220px) / 2);
          }
        }
      }
      padding-top: 0;
    }
    .challenges-section {
      @media (min-width: 992px) {
        padding-bottom: 80px;
      }
      @media (max-width: 991px) {
        &.challenges.above-cat {
          padding-bottom: 90px !important;
        }
      }
      .challenges-container {
        position: relative;
        .cat {
          position: absolute;
          width: 267px;
          left: 0;
          top: -145px;
          @media (min-width: 768px) and (max-width: 992px) {
            width: 180px;
            top: -120px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
          @media (max-width: 767px) {
            width: 180px;
            top: -105px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
      .stats-item {
        > div {
          border: 1px solid #ECECEC;
        }
      }
    }
  }
  .purring-customers-section {
    @media (max-width: 767px) {
      padding-top: 70px !important;
    }
    @media (max-width: 767px) {
      &.purring-customers.above-cat {
        padding-bottom: 100px !important;
      }
    }
    .purring-customers-container {
      position: relative;
      .cat {
        position: absolute;
        width: 267px;
        left: 0;
        top: -145px;
        @media (min-width: 768px) and (max-width: 992px) {
          width: 180px;
          top: -120px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
        @media (max-width: 767px) {
          width: 180px;
          top: -130px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      .stats {
        padding-top: 0;
      }
    }
    .purring-customers-wrapper {
      .swiper-slide {
        text-align: center;
        .team-member {
          max-width: 143px;
          margin: 0 auto;
        }

        h5 {
          font-size: 21px;
          font-weight: 800;
        }

        p {
          font-size: 18px;
        }
      }
    }
    @media (min-width: 992px) {
      .purring-customers-wrapper {
        display: flex;
        justify-content: center;
      }
      .slider-wrapper {
        width: calc(100% - 30px);
      }
    }
    .swiper-button-next {
      @media (min-width: 992px) {
        right: -30px;
      }
    }
    .swiper-button-prev {
      @media (min-width: 992px) {
        left: -40px;
      }
    }
    .rating-stars {
      display: flex;
      align-items: center;
      gap: 4px;
      svg {
        height: 20px;
      }
    }
  }
  .navigation-section {
    padding: 0 !important;
    display: flex;
    background: rgb(236,236,236);
    background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(236,236,236,1) 50%, rgba(249,220,75,1) 50%, rgba(249,220,75,1) 100%);
    @media (max-width: 620px) {
      background: linear-gradient(180deg, rgba(236,236,236,1) 0%, rgba(236,236,236,1) 50%, rgba(249,220,75,1) 50%, rgba(249,220,75,1) 100%);
    }
    .container {
      @media (max-width: 620px) {
        flex-direction: column;
      }
    }
    .navigation-section-item {
      padding: 60px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 50px;
      justify-content: space-between;
      @media (min-width: 1200px) {
        padding: 120px 100px;
      }
    }
    .navigation-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 16px;
      a {
        flex: 1;
        min-width: fit-content;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 16px 7px;
        height: 48px;
        background: #F9DC4B;
        border-radius: 120px;
        font-weight: 800;
        font-size: 18px;
        color: #000000;
        text-decoration: none;
      }
      &.sell a {
        background: #FFFFFF;
      }
    }
  }
  .buyer-how-it-works {
    video {
      background-color: black;
      display: flex;
      align-items: center;
      height: 420px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      @media (max-width: 767px) {
        background-color: #FFF;
        height: auto;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        height: 420px;
      }
      @media (min-width: 992px) and (max-width: 1399px) {
        height: 410px;
        margin-top: 40px;

      }
      @media (min-width: 1400px) {
        height: 455px;
        margin-top: 40px;
      }
    }
  }
  .buyer-seller-benefits-section {
    h1, .h1 {font-size: 36px;}
    .container {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      @media (max-width: 620px) {
        flex-direction: column;
      }
    }
    .buyer-seller-benefits-section-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      @media (max-width: 620px) {
        gap: 20px;
      }
      > div {
        @media (min-width: 768px) {
          min-height: 180px;
        }
        @media (min-width: 621px) and (max-width: 767px) {
          min-height: 300px;
        }
      }
    }
  }
  .press-release-section {
    background: #F9DC4B;
    padding: 36px 0 !important;

    .press-release-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding-right: calc(1.5rem * 0.5);
      padding-left: calc(1.5rem * 0.5);
      @media (max-width: 620px) {
        flex-direction: column;
        h4 {text-align: center;}
      }
      @media (min-width: 768px) and (max-width: 991px) {
        padding-right: calc(20px + calc(1.5rem * 0.5));
        padding-left: calc(20px + calc(1.5rem * 0.5));
      }
      @media (min-width: 992px) {
        padding-right: calc((((100% - 960px) / 2) + calc(1.5rem * 0.5) ));
        padding-left: calc((((100% - 960px) / 2) + calc(1.5rem * 0.5) ));
      }
      @media (min-width: 1200px) {
        padding-right: calc((((100% - 1140px) / 2) + calc(1.5rem * 0.5) ));
        padding-left: calc((((100% - 1140px) / 2) + calc(1.5rem * 0.5) ));
      }
      @media (min-width: 1400px) {
        padding-right: calc((((100% - 1320px) / 2) + calc(1.5rem * 0.5) ));
        padding-left: calc((((100% - 1320px) / 2) + calc(1.5rem * 0.5) ));
      }
      a {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 16px 7px;
        height: 48px;
        background: #FFF;
        border-radius: 120px;
        font-weight: 800;
        font-size: 18px;
        color: #000000;
        text-decoration: none;
      }
    }
  }
  .latest-vehicles-section {
    .container {
      display: flex;
      flex-direction: column;
    }
    .latest-vehicles-section-heading {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      gap: 8px;
      .all-vehicles-navigation {
        @extend .latest-vehicles-section-heading;
        align-items: center;
        span {
          font-weight: 800;
          font-size: 21px;
          text-decoration: none;
          color: $primary-black;
          cursor: pointer;
        }
        svg {
          height: 16px;
          path {
            stroke: $primary-black;
          }
        }
      }
    }
    .swiper-wrapper {
      padding-left: 5px;
    }
    .swiper-pagination {
      margin-bottom: 30px;
    }
    .start-shopping {
      display: flex;
      justify-content: center;
      span {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 16px 7px;
        height: 48px;
        background: $primary-light-yellow;
        border-radius: 120px;
        font-weight: 800;
        font-size: 18px;
        color: #000000;
        text-decoration: none;
        cursor: pointer;
        @media (max-width: 991px) {
          margin-top: 30px;
        }
      }
    }
    .listing-item {
      position: relative;
      flex: 1;
      width: calc(100% - 10px);
      .sale-in-progress-badge {
        height: 32px !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background: $primary-light-yellow;
        border-radius: 6px;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #262102;
      }
      .sold-badge {
        height: 32px !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background: $primary-light-yellow;
        border-radius: 6px;
        color: #262102;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
      }
      .dark-bg-badge {
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background: $primary-text-black;
        border-radius: 6px;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #FFFFFF;
      }
      .status-row {
        position: absolute;
        top: 12px;
        right: 0;
        width: 100%;
        z-index: 10;
        box-sizing: border-box;
      }
      .badges {
        gap: 4px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        @media (min-width: 576px) and (max-width: 1399px)  {
          flex-direction: column;
        }
      }
      .favorite-button {
        height: 32px;
        width: 32px;
        padding: 0;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 96px;
        margin-left: auto;
        &.is-favorite {
          background-color: #181818;
          color: #ffffff;
          border: 1px solid $primary-light-yellow;
          svg path {
            fill: #ffffff;
          }
        }
      }
      .listing-item-card {
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 24px;
        height: calc(100% - 24px);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .listing-details {
        color: $primary-black;
        background-color: #FFFFFF;
        height: calc(100% - 230px);
        .listing-details-title {
          font-weight: 600;
          font-size: 17px;
          line-height: 130%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 8px 16px;
        }
        .listing-details-price-mileage {
          display: flex;
          justify-content: space-between;
          padding: 0px 16px 8px;
        }
        .listing-details-location {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 130%;
          color: $primary-black;
          padding: 16px;
          border-top: 1px solid #B8B6B6;
          height: calc(100% - 70px);
          svg path {
            fill: $primary-black;
          }
          span:first-child {
            display: flex;
            min-width: 18px;
            align-items: flex-start;
            justify-content: center;
          }
        }
      }

      .photo-container {
        display: flex;
        position: relative;
        background-color: #fff;
        overflow: hidden;
        align-items: center;
        height: 230px;
        img {
          object-fit: cover;
          min-width: 100%;
          max-width: 100%;
          max-height: 100%;
          min-height: 100%;
        }
      }
    }
    @media (min-width: 992px) {
      .latest-vehicles-wrapper {
        display: flex;
        justify-content: center;
      }
      .slider-wrapper {
        width: calc(100% - 30px);
      }
    }
    .swiper-button-next {
      @media (min-width: 992px) {
        right: -30px;
      }
    }
    .swiper-button-prev {
      @media (min-width: 992px) {
        left: -40px;
      }
    }
  }
  .press-release-container {
    h1 {
      font-weight: 800;
      font-size: 36px;
    }
    p {
      font-size: 18px;
    }
    .animation-wrapper {
      display: flex;
      align-items: center;
      max-width: 470px;
    }
    .article-header {
      font-weight: 700;
      font-size: 14px;
      color: #000000;
    }
    .buying-selling-card {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border-radius: 10px;
      background-color: $primary-light-yellow;
      max-width: 470px;
      a {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 16px 7px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 120px;
        font-weight: 800;
        font-size: 18px;
        color: #000000;
        text-decoration: none;
      }
      &.bg-white {
        a {
          background-color: $primary-light-yellow;
        }
      }
    }
  }
  .blog-container {
    h1 {
      font-size: 36px;
      font-weight: 800;
      font-family: Gilroy;
      color: $primary-black;
    }
    p {
      font-size: 18px;
    }
    a {
      text-decoration: none;
    }
  }
}
